/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {useMemo, useRef} from 'react';
import {useLocale} from '../../hooks/useLocale';
import {useEvents} from '../../hooks/useEvents';
import {TieredMenu} from 'primereact/tieredmenu';
import {Button} from 'primereact/button';

type PresetItem = {
  label: string;
  value?: number | string;
  icon?: string;
  items?: PresetItem[];
  command?: (e: any) => void;
};

export const SilenceSelector = () => {
  const {$t, locale} = useLocale();
  const menu = useRef<TieredMenu>(null);
  const menuContainer = useRef<HTMLDivElement>(null);
  const {silenceConfig, setSilenceConfig} = useEvents();
  const slilencePresets = useMemo<PresetItem[]>(() => {
    if (silenceConfig.start > 0 || silenceConfig.time > 0) {
      return [
        {
          label: $t('silence.turnOnNotifications'),
          icon: 'mdi mdi-18px mdi-bell-plus',
          command: () =>
            setSilenceConfig({
              ...silenceConfig,
              start: 0,
              time: 0
            })
        }
      ];
    }

    return [
      {
        label: $t('silence.turnOffNotifications'),
        icon: 'mdi mdi-18px mdi-bell-sleep',
        items: [
          {
            label: `${$t('silence.10min')}`,
            value: 10 * 60 * 1000,
            command: (e) => setPreset(e.item.value)
          },
          {
            label: `${$t('silence.30min')}`,
            value: 30 * 60 * 1000,
            command: (e) => setPreset(e.item.value)
          },
          {
            label: `${$t('silence.1h')}`,
            value: 60 * 60 * 1000,
            command: (e) => setPreset(e.item.value)
          },
          {
            label: `${$t('silence.3h')}`,
            value: 3 * 60 * 60 * 1000,
            command: (e) => setPreset(e.item.value)
          },
          {
            label: `${$t('silence.8h')}`,
            value: 8 * 60 * 60 * 1000,
            command: (e) => setPreset(e.item.value)
          },
          {
            label: `${$t('silence.forever')}`,
            value: Infinity,
            command: (e) => setPreset(e.item.value)
          }
        ]
      },
      {
        label: silenceConfig.withSound ? $t('silence.turnOffNotificationSound') : $t('silence.turnOnNotificationSound'),
        icon: `mdi mdi-18px mdi-music-${silenceConfig.withSound ? 'note' : 'note-off'}`,
        command: () => toggleSoundState()
      }
    ];
  }, [locale, silenceConfig]);

  const setPreset = (value: number) => {
    setSilenceConfig({
      ...silenceConfig,
      start: Date.now(),
      time: value
    });
  };

  const toggleSoundState = () => {
    setSilenceConfig({
      ...silenceConfig,
      withSound: !silenceConfig.withSound
    });
  };

  return (
    <div ref={menuContainer} style={{position: 'relative'}}>
      <TieredMenu model={slilencePresets} ref={menu} appendTo={menuContainer.current} css={tiredMenu} popup />
      <Button
        icon={`mdi mdi-20px mdi-volume${!silenceConfig.withSound || silenceConfig.time > 0 ? '-off' : '-high'}`}
        className="p-button-text p-button-sm"
        onClick={(e) => menu.current?.toggle(e)}
      />
    </div>
  );
};

const tiredMenu = css`
  left: 0 !important;
  top: auto !important;
  bottom: 0;
  transform: translateY(100%);

  .p-menuitem {
    margin-bottom: calc(4rem / var(--bfs));

    .p-submenu-list {
      padding: 0 0 0 calc(4rem / var(--bfs));
    }
  }
`;
