import {useLayoutEffect, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {getTimeLeft} from '../eventUtils';

export function useEventExpirationTime(event?: IAssignmentEvent) {
  const [time, setTime] = useState(() => Date.now());
  useLayoutEffect(() => {
    if (event) {
      const now = Date.now();
      setTime(now);
      const left = getTimeLeft(event, now);
      if (left) {
        const t = setTimeout(() => {
          setTime(Date.now());
        }, left);
        return () => {
          clearTimeout(t);
        };
      }
    }
    return undefined;
  }, [event]);
  return time;
}
