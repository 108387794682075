/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {SilenceSelector} from './SilenceSelector';

// language=SCSS
const subPanelContainerCss = css`
  & {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    border: calc(1rem / var(--bfs)) solid transparent;
  }
`;

// language=SCSS
const subPanelHeaderCss = css`
  display: flex;
  align-items: center;
  & {
    padding: var(--spacer) var(--spacer-xs);
  }
  & > h3 {
    padding: calc(12rem / var(--bfs)) 0;
    font-size: calc(16rem / var(--bfs));
    font-weight: 500;
    margin: 0;
  }
`;

const subPanelContentCss = css`
  flex-shrink: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

const SubPanel: FC<{name: string}> = ({name, children}) => {
  return (
    <div css={subPanelContainerCss}>
      <div css={subPanelHeaderCss}>
        <SilenceSelector />
        <h3>{name}</h3>
      </div>
      <div css={subPanelContentCss}>{children}</div>
    </div>
  );
};

export default SubPanel;
