import {useCallback, useLayoutEffect, useRef, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';

export function useMountedRef() {
  const ref = useRef(false);
  useLayoutEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  });
  return ref;
}

export function useSelection<T>(emptyValue: T, initialValue = emptyValue): [T, (newValue: T) => void, () => void] {
  const [selected, setSelected] = useState<T>(initialValue);
  const select = useCallback(
    (newValue: T) => {
      setSelected(newValue);
    },
    [setSelected]
  );
  const reset = useCallback(() => {
    setSelected(emptyValue);
  }, [setSelected, emptyValue]);
  return [selected, select, reset];
}

export function keys<T extends {[k: string]: any}>(obj: T): Array<keyof T> {
  return Object.keys(obj);
}

export function getKey(event: IAssignmentEvent) {
  return event.id;
}
