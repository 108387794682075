import React, {FC, Reducer, useCallback, useContext, useReducer} from 'react';

interface IWidgetState {
  isShrunk: boolean;
  setIsShrunk: (value: boolean) => void;
  dialogOpen: boolean;
  setDialogOpen: (value: boolean) => void;
}

const widgetStateInitialValue: IWidgetState = {
  isShrunk: true,
  setIsShrunk: () => {},
  dialogOpen: false,
  setDialogOpen: () => {}
};

const WidgetStateContext = React.createContext<IWidgetState>(widgetStateInitialValue);

type WidgetStateAction = SetShrinkAction;

type SetShrinkAction = ['setIsShrunk' | 'setDialogOpen', boolean];

const WidgetStateReducer: Reducer<IWidgetState, WidgetStateAction> = (prevState, [action, payload]) => {
  switch (action) {
    case 'setIsShrunk':
      return {
        ...prevState,
        isShrunk: payload
      };
    case 'setDialogOpen':
      return {
        ...prevState,
        dialogOpen: payload
      };
    default:
      throw Error;
  }
};

export const WidgetStateContextProvider: FC = ({children}) => {
  const setIsShrunk = useCallback((value: boolean) => {
    dispatch(['setIsShrunk', value]);
  }, []);
  const setDialogOpen = useCallback((value: boolean) => {
    dispatch(['setDialogOpen', value]);
  }, []);
  const [value, dispatch] = useReducer(WidgetStateReducer, {...widgetStateInitialValue, setIsShrunk, setDialogOpen});
  return <WidgetStateContext.Provider value={value}>{children}</WidgetStateContext.Provider>;
};

export function useWidgetState() {
  return useContext(WidgetStateContext);
}
