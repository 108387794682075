import React, {useContext} from 'react';
import {ToastProvider} from './hooks/useToastRef';
import {Panel} from './components/panel/Panel';
import {ObserverProvider} from './hooks/useObserver';
import {LocaleProvider} from './hooks/useLocale';
import {NotificationSocketProvider} from './hooks/useNotificationSocket';
import {WidgetStateContextProvider} from './hooks/useWidgetState';
import {EventsProvider} from './hooks/useEvents';
import {EventTypesProvider} from './providers/EventTypesProvider';
import {IWidgetProps} from './IWidgetProps';

const WidgetPropsContext = React.createContext<IWidgetProps>(null!);
export const useWidgetProps = () => useContext(WidgetPropsContext);

export const Root = (widgetProps: IWidgetProps) => {
  return (
    <WidgetPropsContext.Provider value={widgetProps}>
      <ObserverProvider eventBus={widgetProps.props?.eventBus}>
        <LocaleProvider>
          <NotificationSocketProvider>
            <EventTypesProvider>
              <WidgetStateContextProvider>
                <ToastProvider position={'bottom-left'}>
                  <EventsProvider>
                    <Panel />
                  </EventsProvider>
                </ToastProvider>
              </WidgetStateContextProvider>
            </EventTypesProvider>
          </NotificationSocketProvider>
        </LocaleProvider>
      </ObserverProvider>
    </WidgetPropsContext.Provider>
  );
};
