import React, {FC, MutableRefObject, createContext, useContext, useRef} from 'react';
import {Toast, ToastProps} from 'primereact/toast';

const ToastContext = createContext<MutableRefObject<Toast | null>>(null!);

export const useToastRef = () => useContext(ToastContext);

export const ToastProvider: FC<ToastProps> = ({children, ...rest}) => {
  const ref = useRef<Toast | null>(null);

  return (
    <ToastContext.Provider value={ref}>
      {children}
      <Toast ref={ref} {...rest} />
    </ToastContext.Provider>
  );
};
