/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, useMemo} from 'react';
import {NotificationList} from '../notifications/NotificationList';
import {useWidgetState} from '../../hooks/useWidgetState';
import {AlarmIcon} from '../ui/icons/AlarmIcon';
import {SvgButton} from '../ui/SvgButton';
import {useEvents} from '../../hooks/useEvents';
import {fadeIn} from '../ui/fadeIn';
import {useActiveEvents} from '../../hooks/useActiveEvents';
import {E2EModule} from '../../__test__';
import {ReactionDialog} from '../reactionDialog/ReactionDialog';
import {getKey} from '../../utils';

// language=SCSS
const containerCss = css`
  & {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
  }
`;

// language=SCSS
const blurCss = css`
  & {
    backdrop-filter: blur(5px);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 61.8%, rgba(0, 0, 0, 0) calc(100% - 70rem / var(--bfs)));
  }
`;

// language=SCSS
const panelRootCss = css`
  & {
    animation: ${fadeIn} 200ms ease;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: calc(375rem / var(--bfs));
  }
  & > *:first-child {
    height: 50%;
    flex-grow: 1;
  }
  & > *:nth-child(2) {
    max-height: 50%;
  }
`;

const ShrinkButton: FC<{isSilence: boolean}> = ({isSilence}) => {
  const {isShrunk, setIsShrunk, dialogOpen} = useWidgetState();
  const {list} = useEvents();
  const [active] = useActiveEvents(list);

  return dialogOpen ? null : (
    <SvgButton
      data-cy={E2EModule.attributes.bellButton}
      className={`p-button-blurred ${isSilence ? '--off' : ''}`}
      style={{width: 'calc(48rem/var(--bfs))'}}
      onClick={() => setIsShrunk(!isShrunk)}
      css={shrinkButtonCss}>
      <AlarmIcon isActive={active.length > 0} isSilence={isSilence} />
    </SvgButton>
  );
};

// language=SCSS
const shrinkButtonCss = css`
  & {
    position: absolute;
    right: calc(30rem / var(--bfs));
    top: calc(30rem / var(--bfs));
    box-shadow: var(--shadow-raised);
  }
  &.--off {
    backdrop-filter: blur(15px);
    background: rgba(var(--error-color-raw), 0.1) var(--input-error-gradient);
    color: var(--text-color-active);
    border: calc(var(--input-border-width) * 2) solid var(--error-color);

    &:hover {
      border-color: var(--error-color);
      background: rgba(var(--error-color-raw), 0.1) var(--input-error-gradient);
    }
  }
`;

export const Panel: FC = () => {
  const {isShrunk} = useWidgetState();
  const {criticalEvents, setCriticalEvents, silenceConfig} = useEvents();

  const shiftCriticalEvent = () => {
    const first = criticalEvents.values().next().value;
    setCriticalEvents((prev) => {
      prev.delete(getKey(first));
      return new Map(prev);
    });
  };

  const ActualCriticalEvent = useMemo(() => {
    if (!criticalEvents.size) return;
    const first = criticalEvents.values().next().value;
    return !isShrunk && <ReactionDialog key={first.id} event={first} onHide={shiftCriticalEvent} />;
  }, [criticalEvents, isShrunk]);

  return (
    <div
      style={{zIndex: !isShrunk ? 9999 : 3}}
      data-open={!isShrunk}
      css={[
        containerCss,
        // blurCss should be separated because of Safari bug
        !isShrunk && blurCss
      ]}>
      {!isShrunk && (
        <div css={panelRootCss}>
          <NotificationList />
        </div>
      )}
      <ShrinkButton isSilence={silenceConfig.time > 0} />
      {ActualCriticalEvent}
    </div>
  );
};
