import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {AssignmentEventStatus} from './ts';

export function getIsReacted(event: IAssignmentEvent) {
  return event.status !== AssignmentEventStatus.NotHandled;
}

export function getExpiration(event: IAssignmentEvent) {
  return (event.handlingTimeout && event.timestamp + event.handlingTimeout * 1000) || undefined;
}

export function getTimeLeft(event: IAssignmentEvent, time: number) {
  const exp = getExpiration(event);
  return (exp && Math.max(0, exp - time)) || undefined;
}

export function getNeedsReaction(event: IAssignmentEvent, time: number) {
  const exp = getExpiration(event);
  return !!exp && time < exp && !getIsReacted(event);
}

export function getIsMissed(event: IAssignmentEvent, time: number) {
  const exp = getExpiration(event);
  return !!exp && time >= exp && !getIsReacted(event);
}

export function cutActiveEvents(time: number, events: IAssignmentEvent[]): [IAssignmentEvent[], IAssignmentEvent[]] {
  const active: IAssignmentEvent[] = [];
  const rest: IAssignmentEvent[] = [];
  events.forEach((ev) => {
    if (getNeedsReaction(ev, time)) {
      active.push(ev);
    } else {
      rest.push(ev);
    }
  }, []);
  return [active.sort((a, b) => (getExpiration(a) || 0) - (getExpiration(b) || 0)), rest];
}
