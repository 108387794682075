// language=SCSS
import {css} from '@emotion/react';

export const createIconSizesCss = (basicSize: number, totalSize: number) => css`
  & {
    position: relative;
    height: calc(${basicSize}rem / var(--bfs));
    width: calc(${basicSize}rem / var(--bfs));

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(${totalSize}rem / var(--bfs));
      height: calc(${totalSize}rem / var(--bfs));
    }
  }
`;
