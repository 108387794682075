/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import React, {useLayoutEffect, useRef, useState} from 'react';
import {EventDescription, IOTarget} from './EventDescription';
import DateSeparator from './DateSeparator';
import {useSelection} from '../../utils';
import {ReactionDialog} from '../reactionDialog/ReactionDialog';
import SubPanel from '../panel/SubPanel';
import {useLocale} from '../../hooks/useLocale';
import {useEvents} from '../../hooks/useEvents';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {useActiveEvents} from '../../hooks/useActiveEvents';
import {E2EModule} from '../../__test__';

// language=SCSS
const eventListCss = css`
  & {
    padding: 0 calc(var(--spacer-xs) * 2.5) calc(200rem / var(--bfs)) var(--spacer-xs);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  & > * {
    margin-bottom: calc(10rem / var(--bfs));
  }
  ,
  & > *:last-child {
    margin-bottom: 0;
  }
`;

function groupByDate(events: IAssignmentEvent[]) {
  const map = new Map<number, Array<IAssignmentEvent>>();
  events.forEach((entry) => {
    const date = new Date(entry.timestamp);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const array = map.get(date.getTime());
    if (array) {
      array.push(entry);
    } else {
      map.set(date.getTime(), [entry]);
    }
  });
  return [...map];
}

const createIo = (root: HTMLElement) => {
  return new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const target: IOTarget = entry.target;
        if (typeof target.onIntersect === 'function') {
          target.onIntersect(entry);
        }
      });
    },
    {root}
  );
};

const emptyValue = '_empty';
export const NotificationList = () => {
  const {$t} = useLocale();
  const {map, list} = useEvents();
  const [selectedKey, select, reset] = useSelection<string>(emptyValue);
  const selected = map.get(selectedKey);
  const dialog = selected && <ReactionDialog key={selected.id} event={selected} onHide={reset} />;
  const [active, rest] = useActiveEvents(list);
  const groups: Array<[number, Array<IAssignmentEvent>]> = [[-1, active], ...groupByDate(rest)];
  const ref = useRef<HTMLDivElement | null>(null);
  const [io, setIo] = useState<IntersectionObserver>();
  useLayoutEffect(() => {
    const node = ref.current;
    if (node !== null) setIo(createIo(node));
  }, []);
  useLayoutEffect(() => (io ? () => io.disconnect() : undefined), [io]);
  return (
    <SubPanel name={$t('events')}>
      <div ref={ref} css={eventListCss} data-cy={E2EModule.attributes.messagesList}>
        {groups.map((group) => {
          return (
            <React.Fragment key={group[0]}>
              {group[0] > -1 && <DateSeparator timestamp={group[0]} />}
              {group[1].map((event) => {
                const isSelected = selectedKey === event.id;
                return (
                  <EventDescription
                    io={io}
                    key={event.id}
                    onClick={(e) => {
                      if (!e.defaultPrevented) {
                        isSelected ? reset() : select(event.id);
                      }
                    }}
                    event={event}
                    selected={isSelected}
                  />
                );
              })}
            </React.Fragment>
          );
        })}
        {dialog}
      </div>
    </SubPanel>
  );
};
