import {useEffect, useState} from 'react';

const currentTime = () => Date.now();

export const useCurrentTime = (delay: number) => {
  const [time, setTime] = useState(currentTime);
  useEffect(() => {
    const id = setInterval(() => {
      setTime(currentTime());
    }, delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
  return time;
};
