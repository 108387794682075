import {createCamerasConnection, IAssignmentEvent, listEntities} from '@netvision/lib-api-gateway';

export function fetchAssignmentEvents(): Promise<IAssignmentEvent[]> {
  return listEntities<IAssignmentEvent>(createCamerasConnection(), {
    type: 'AssignmentEvent',
    limit: 100,
    orderBy: '!timestamp',
    count: true,
    q: 'entityType==Camera'
  }).then(({results}) => results);
}
