/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import type {SC} from '../../utilTypes';

const formatter = new Intl.DateTimeFormat('ru-ru', {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
});

// language=SCSS
const dateSeparatorCss = css`
  & {
    display: flex;
    margin-top: var(--spacer-sm);
    margin-bottom: var(--spacer-xs);
  }
  &:first-child {
    margin-top: 0;
  }
  & > span {
    padding: calc(12rem / var(--bfs)) calc(15rem / var(--bfs));
    background: var(--secondary-color-alt-2);
    border-radius: var(--border-radius);
    font-size: calc(14rem / var(--bfs));
    font-weight: 400;
    margin: 0;
    flex-shrink: 1;
  }
`;

const DateSeparator: SC<{timestamp: number}> = ({className, timestamp}) => {
  const dateString = formatter.format(new Date(timestamp));
  return (
    <div className={className} css={dateSeparatorCss}>
      <span>{dateString}</span>
    </div>
  );
};

export default DateSeparator;
