import {useCallback, useEffect, useState} from 'react';
import {createCamerasConnection, IEntity, listEntities} from '@netvision/lib-api-gateway';
import {useNotificationSocket} from './useNotificationSocket';

const aMap = new Map();

export const useEntityMap = <E extends IEntity>(type: E['type'], getKey: (en: E) => string, refine?: (en: E) => E) => {
  const [entities, setEntities] = useState<Map<string, E>>(aMap);
  useEffect(() => {
    let aborted = false;
    listEntities<E>(createCamerasConnection(), {type, limit: 1000}).then((res) => {
      if (!aborted) {
        let entities = res.results;
        if (typeof refine === 'function') {
          entities = res.results.map(refine);
        }
        setEntities(new Map(entities.map((en) => [getKey(en), en])));
      }
    });
    return () => {
      aborted = true;
    };
    // exclude refine
    // eslint-disable-next-line
  }, [type, getKey, refine]);
  useNotificationSocket<E>(
    type,
    useCallback(
      (newEntity) => {
        setEntities((current) => {
          current.set(getKey(newEntity), newEntity);
          return new Map(current);
        });
      },
      [getKey]
    )
  );
  return entities;
};
