import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {cutActiveEvents, getTimeLeft} from '../eventUtils';
import {useLayoutEffect, useState} from 'react';

const empty: [] = [];
const now = () => Date.now();

export const useActiveEvents = (events: IAssignmentEvent[]): [IAssignmentEvent[], IAssignmentEvent[]] => {
  const [time, setTime] = useState(now);
  const [active, setActive] = useState<IAssignmentEvent[]>(empty);
  const [rest, setRest] = useState<IAssignmentEvent[]>(empty);
  useLayoutEffect(() => {
    const [active, rest] = cutActiveEvents(now(), events);
    setActive(active);
    setRest(rest);

    if (active.length > 0) {
      const left = getTimeLeft(active[0], time);
      if (left) {
        const t = setTimeout(() => {
          setTime(now());
        }, left);
        return () => {
          clearTimeout(t);
        };
      }
    }

    return undefined;
  }, [time, events]);
  return [active, rest];
};
