/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {createElement, FC, useEffect, useMemo, useRef, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {getExpiration, getIsMissed, getNeedsReaction} from '../../eventUtils';
import {useEvents} from '../../hooks/useEvents';
import {useEventExpirationTime} from '../../hooks/useEventExpirationTime';
import {Success} from '../ui/icons/Success';
import {Drill} from '../ui/icons/Drill';
import {Attention} from '../ui/icons/Attention';
import {Button} from 'primereact/button';
import {Close} from '../ui/icons/Close';
import {CountDown} from '../ui/CountDown';
import {fadeIn} from '../ui/fadeIn';
import {useEventTypes} from '../../providers/EventTypesProvider';
import {AssignmentEventStatus} from '../../ts';
import {E2EModule} from '../../__test__';

export interface IOTarget extends Element {
  onIntersect?: (entry: IntersectionObserverEntry) => void;
}

const IntersectionObserverClient: FC<JSX.IntrinsicElements['div'] & {io?: IntersectionObserver}> = (props) => {
  const {io, children, ...rest} = props;
  const ref = useRef<IOTarget | null>(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    let mounted = true;
    const node = ref.current;
    if (node === null || io === undefined) return;
    node.onIntersect = (entry) => {
      if (mounted) {
        if (entry.intersectionRatio > 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    };
    io.observe(node);
    return () => {
      mounted = false;
      io.unobserve(node);
    };
  }, [io]);
  return createElement('div', {...rest, ref}, visible && children);
};

export const EventDescription: FC<
  {selected?: boolean; event: IAssignmentEvent} & JSX.IntrinsicElements['div'] & {io?: IntersectionObserver}
> = ({event, selected = false, ...rest}) => {
  const eventTypes = useEventTypes();
  const {criticalEventTypes} = useEvents();
  const eventType = eventTypes.get(event.eventType);
  const title = eventType?.[`localeRu`]?.title || eventType?.title || eventType?.name;
  const assignmentTitle = `${event.assignment?.title || ''} - `;
  const entityTitle = event.assignment?.entityTitle || '';
  const expiration = getExpiration(event);
  const time = useEventExpirationTime(event);
  const needsReaction = getNeedsReaction(event, time);
  const isUnhandledCriticalEvent = useMemo(
    () => criticalEventTypes.has(event.eventType) && event.status === AssignmentEventStatus.NotHandled,
    [criticalEventTypes, event]
  );
  return (
    <IntersectionObserverClient
      {...rest}
      css={containerCss}
      data-cy={E2EModule.attributes.eventItem}
      data-selected={selected}
      className={`${isUnhandledCriticalEvent ? '--critical' : ''}`}>
      <Icon event={event} />
      <div css={labelContainerCss}>
        <div css={labelCss} title={title}>
          {title}
        </div>
        <div css={subLabelCss} title={assignmentTitle}>
          {assignmentTitle}
        </div>
        <div css={subLabelCss} title={entityTitle}>
          {entityTitle}
        </div>
      </div>
      <div css={rightCss}>
        {expiration && needsReaction ? (
          <CountDown endTime={expiration} css={[timeStampCss, countDownCss]} />
        ) : (
          <TimeStamp time={event.timestamp} />
        )}
        <div css={bottomRightCss}>
          <Status event={event} time={time} />
          {!needsReaction && <Hide event={event} />}
        </div>
      </div>
    </IntersectionObserverClient>
  );
};

// language=SCSS
const containerCss = css`
  & {
    position: relative;
    padding: calc(var(--spacer-xs) * 1.3) calc(var(--spacer-xs) * 1.4) calc(var(--spacer-xs));
    border: 0 solid var(--primary-color);
    background: var(--surface-a) var(--event-gradient);
    border-radius: var(--border-radius-sm);
    box-shadow: var(--shadow-raised-alt-1);

    font-size: var(--font-size-sm);

    display: flex;
    height: calc(68rem / var(--bfs));

    &:hover {
      cursor: pointer;
      padding: calc(var(--spacer-xs) * 1.3 - 1rem / var(--bfs)) calc(var(--spacer-xs) * 1.4 - 1rem / var(--bfs))
        calc(var(--spacer-xs) - 1rem / var(--bfs));
      border: calc(1rem / var(--bfs)) solid var(--primary-color);
    }

    &[data-selected='true'] {
      background: var(--surface-a) var(--selection-gradient);
      padding: calc(var(--spacer-xs) * 1.3 - 1rem / var(--bfs)) calc(var(--spacer-xs) * 1.4 - 1rem / var(--bfs))
        calc(var(--spacer-xs) - 1rem / var(--bfs));
      border: calc(1rem / var(--bfs)) solid var(--primary-color);
    }
  }

  & > * {
    animation: ${fadeIn} 200ms ease;
    margin-right: calc(var(--spacer-xs) * 1.4);
  }

  & > *:last-child {
    margin-right: 0;
  }

  &.--critical {
    background: rgba(var(--error-color-raw), 0.1) var(--input-error-gradient);
    border-color: var(--error-color);

    &:hover {
      border: calc(1rem / var(--bfs)) solid var(--error-color);
    }
  }
`;

// language=SCSS
const rightCss = css`
  & {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: calc(50rem / var(--bfs));
  }
`;

// language=SCSS
const bottomRightCss = css`
  & {
    display: flex;
    align-items: center;
  }
  & > *:first-child:not(:last-child) {
    margin-right: calc(var(--spacer-xs) / 2);
  }
`;

const Icon: FC<{event: IAssignmentEvent}> = ({event}) => {
  const eventTypes = useEventTypes();
  const eventType = eventTypes.get(event.eventType);

  const icon = eventType?.icon?.iconClass || '';
  const color = eventType?.icon?.color || undefined;
  return <div css={iconCss} className={icon} style={{color}} />;
};

// language=SCSS
const iconCss = css`
  & {
    position: relative;
    height: calc(20rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    font-size: calc(20rem / var(--bfs));
  }
`;

// language=SCSS
const labelContainerCss = css`
  & {
    flex-grow: 1;
    max-width: calc(195rem / var(--bfs));
  }
  & > div:first-of-type {
    margin-bottom: calc(var(--spacer-xs) * 0.6);
  }
`;

// language=SCSS
const labelCss = css`
  & {
    color: var(--text-color-secondary);
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

// language=SCSS
const subLabelCss = css`
  & {
    color: var(--text-color);
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const formatter = new Intl.DateTimeFormat('ru-ru', {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
});

const TimeStamp: FC<{time: number}> = ({time}) => {
  const date = new Date(time);
  return <div css={timeStampCss}>{formatter.format(date)}</div>;
};

// language=SCSS
const timeStampCss = css`
  & {
    color: var(--text-color-secondary);
  }
`;

const countDownCss = css`
  & {
    color: var(--text-color);
    width: calc(34rem / var(--bfs));
    text-align: left;
  }
`;

const Status: FC<{event: IAssignmentEvent | {status: AssignmentEventStatus}; time: number}> = ({event, time}) => {
  const {status} = event;
  if (getIsMissed(event as IAssignmentEvent, time)) {
    return <Close />;
  }

  if (status === AssignmentEventStatus.ViolationDetected) {
    return <Drill css={statusCss} />;
  }
  if (status === AssignmentEventStatus.FalsePositive) {
    return <Success css={statusCss} />;
  }
  if (status === AssignmentEventStatus.Handled) {
    return <Attention css={statusCss} />;
  }
  return null;
};

const statusCss = css`
  & {
    transform: translate(-50%, -50%) scale(calc(4 / 5)) !important;
    transform-origin: center;
  }
`;

const Hide: FC<{event: IAssignmentEvent}> = ({event}) => {
  const {removeEvent} = useEvents();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        removeEvent(event.id);
      }}
      className={'p-button-rounded p-button-text'}
      icon={'mdi mdi-eye'}
      css={hideCss}
    />
  );
};

// language=SCSS
const hideCss = css`
  &.p-button.p-button-text {
    color: var(--text-color-secondary);
    height: calc(20rem / var(--bfs));
    width: calc(20rem / var(--bfs));
    padding: calc(2rem / var(--bfs));
    font-size: calc(18rem / var(--bfs));
  }
`;
