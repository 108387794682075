import {useEffect, useRef} from 'react';

export const useSound = () => {
  const sound = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    sound.current = new Audio('/assets/notification.wav');
    return () => {
      sound.current = null;
    };
  }, []);

  return sound;
};
