import React, {ReactNode, createContext, useContext, useEffect, useState} from 'react';
import {LocaleCodes, TLocales} from '../IWidgetProps';
import {locales} from '../locales';
import {get, merge} from 'lodash-es';

interface LocaleProviderProps {
  children: ReactNode;
}

interface LocaleContext {
  locale: LocaleCodes;
  setLocale: (locale: LocaleCodes) => void;
  setExternalLocales: (value: TLocales) => void;
  $t: <T>(path: string, ...rest: Array<string | number>) => T | string;
}

const LocaleContext = createContext<LocaleContext>({
  locale: 'ru',
  setLocale: (locale: LocaleCodes) => {},
  setExternalLocales: () => {},
  $t: <T,>(path: string, ...rest: Array<string | number>): T | string => path
});

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = ({children}: LocaleProviderProps) => {
  let [value, setValue] = useState<TLocales>(locales);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [locale, setLocale] = useState<LocaleCodes>('ru');
  const $t = <T,>(path: string, ...rest: Array<string | number>): T | string => {
    const data = get(value, `${locale}.${path}`);
    if (typeof data !== 'string') return data as T;
    const message: string = String(data);
    return !rest.length
      ? message
      : message
          .split(' ')
          .reduce((acc, next) => {
            acc.push(next.includes('{param}') ? next.replace('{param}', String(rest.shift())) : next);
            return acc;
          }, [] as Array<string | number>)
          .join(' ');
  };

  const setExternalLocales = (value: TLocales) => {
    setValue(merge(locales, value));
  };

  useEffect(() => {
    if (isFirstRender) {
      const storagedLocale = localStorage.getItem('netvision:localeConfig');

      if (storagedLocale) {
        const localeConfig = JSON.parse(storagedLocale);
        setLocale(localeConfig.name as LocaleCodes);
      } else {
        setLocale('ru');
      }

      setIsFirstRender(false);
    }
  }, [isFirstRender, locale]);

  return (
    <LocaleContext.Provider value={{$t, locale, setLocale, setExternalLocales}}>{children}</LocaleContext.Provider>
  );
};
