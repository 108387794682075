import {ObjectType} from '@netvision/lib-api-gateway';
import {AssignmentEventStatus} from '../ts';

export const localesEN = {
  eventStatusLocale: {
    [AssignmentEventStatus.ViolationDetected]: 'Anxiety',
    [AssignmentEventStatus.FalsePositive]: 'False',
    [AssignmentEventStatus.Handled]: 'No threat',
    [AssignmentEventStatus.NotHandled]: 'Not processed'
  },
  commandsLocale: {
    dismiss: 'Hide'
  },
  notificationSettingsTableLocale: {
    commonSettings: 'General settings',
    userSettings: 'Custom settings',
    assigmentPriority: 'Priority',
    assigmentType: 'Analytics type',
    anyPriority: 'Any priority',
    anyAssignmentType: 'Any type of analytics',
    sendNotification: 'Send notifications',
    showAlarm: 'Needs a response',
    handlingTimeout: 'Reaction time',
    save: 'Save',
    add: 'Add',
    cancel: 'Cancel',
    headerEdit: 'Edit setting',
    headerCreate: 'Create setting',
    yes: 'Yes',
    no: 'No',
    confirmDelete: 'Delete setting'
  },
  reactionDialog: {
    alert: 'Anxiety',
    falsePositive: 'False',
    handled: 'No threat',
    assignment: 'Analytics',
    camera: 'Camera',
    date: 'Date',
    holdPressed: 'Hold the button!'
  },
  eventFieldsLocale: {
    timeLabel: 'Time',
    endAtLabel: 'End',
    inProcess: 'Still in progress',
    eventTypeLabel: 'Event type',
    statusLabel: 'Status',
    reportedLabel: 'Processed',
    objectLabel: 'Object',
    assignmentNameLabel: 'Analytics',
    assignmentTypeLabel: 'Analytics type',
    priorityLabel: 'Priority',
    plateNumberLabel: 'GRZ number',
    vehicleBrandLabel: 'Vehicle mark',
    vehicleModelLabel: 'Vehicle model',
    vehicleSpeedLabel: 'Speed',
    speedUnknown: 'Not determined',
    modelUnknown: 'Not determined',
    brandUnknown: 'Not determined'
  },
  externalFilePreviewLocale: {
    previewIsNotReady: 'Preview still being processed',
    previewIsNotFound: 'Preview frame not found',
    previewLoadingError: 'Failed to load preview'
  },
  count: {
    [ObjectType.Person]: 'People',
    [ObjectType.Car]: 'Transport',
    [ObjectType.Bicycle]: 'Bicycles',
    [ObjectType.Motorcycle]: 'Motorcycles',
    [ObjectType.Animal]: 'Animals',
    [ObjectType.Roadsign]: 'Road signs'
  },
  silence: {
    '10min': '10 minutes',
    '30min': '30 minutes',
    '1h': '1 hour',
    '3h': '3 hours',
    '8h': '8 hours',
    forever: 'Permanently',
    turnOnNotifications: 'Enable alerts',
    turnOffNotifications: 'Disable alerts',
    turnOnNotificationSound: 'Enable alert sound',
    turnOffNotificationSound: 'Mute alerts'
  },
  toasts: {
    notificationsDisabledSummary: 'Reminder',
    notificationsDisabledDetails: 'You have disabled notifications about critical events'
  },
  events: 'Active events',
  settings: 'Settings',
  counters: 'Counters',
  archiveLabel: 'Archive'
};
