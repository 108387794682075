/** @jsx jsx */
import {jsx} from '@emotion/react';
import {FC, useEffect, useRef} from 'react';
import {useWidgetProps} from '../../Root';
import {Widget} from '@netvision/lib-widget-renderer';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {IWidgetProps} from '../../IWidgetProps';
import {useEvents} from '../../hooks/useEvents';

export const EventDetailsAdapter: FC<{
  event: IAssignmentEvent;
  containerId?: string;
  onHide: () => void;
}> = ({event, onHide, containerId}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {mountChildren, areas} = useWidgetProps();
  const {criticalEvents, setCriticalEvents} = useEvents();
  useEffect(() => {
    const node = ref.current;
    const dialog = getDialogWidget(areas);
    if (!node || !dialog) return undefined;
    const unmount = mountChildren(node, [
      {
        ...dialog,
        props: {
          ...dialog.props,
          event,
          containerId,
          showActions: true,
          onHide,
          onCleanQueue: criticalEvents.size > 0 ? () => setCriticalEvents(new Map()) : null
        }
      }
    ]);
    return () => {
      unmount();
    };
  }, [event]);

  return <div ref={ref} />;
};

function getDialogWidget(areas: IWidgetProps['areas']): Widget | null {
  let dialog = null;
  if (Array.isArray(areas)) {
    areas.forEach((area) => {
      if (area.name === 'dialog' && area.children && area.children.length > 0) {
        dialog = area.children[0];
      }
    });
  }
  return dialog;
}
