import React, {FC, createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {fetchAssignmentEvents} from '../requests/AssignmentEvent';
import {useNotificationSocket} from './useNotificationSocket';
import {useEventTypes} from '../providers/EventTypesProvider';
import {useSound} from './useSound';
import {getKey, useMountedRef} from '../utils';
import {SilenceTime} from '../utilTypes';
import {name} from '../../package.json';
import {useWidgetState} from './useWidgetState';

export type IEventsMap = Map<ReturnType<typeof getKey>, IAssignmentEvent>;

export interface IEventsContext {
  map: IEventsMap;
  list: IAssignmentEvent[];
  silenceConfig: SilenceTime;
  criticalEvents: IEventsMap;
  criticalEventTypes: Set<string>;
  setCriticalEvents: React.Dispatch<React.SetStateAction<IEventsMap>>;
  setSilenceConfig: React.Dispatch<React.SetStateAction<SilenceTime>>;
  removeEvent: (id: string) => void;
}

const EventsCtx = createContext<IEventsContext>(null!);

export const EventsProvider: FC = ({children}) => {
  const mountedRef = useMountedRef();
  const eventTypes = useEventTypes();
  const sound = useSound();
  const {isShrunk, setIsShrunk} = useWidgetState();
  const [events, setEvents] = useState<IEventsMap>(new Map());
  const [criticalEvents, setCriticalEvents] = useState<IEventsMap>(new Map());
  const [silenceConfig, setSilenceConfig] = useState<SilenceTime>({
    start: 0,
    time: Infinity,
    withSound: true
  });

  const isSilenceTimeExpired = (config: SilenceTime) => {
    const time = config.time === null ? Infinity : config.time;
    return Date.now() - config.start >= time;
  };

  const removeEvent = useCallback((id: string) => {
    setEvents((prev) => {
      prev.delete(id);
      return new Map(prev);
    });
    setCriticalEvents((prev) => {
      prev.delete(id);
      return new Map(prev);
    });
  }, []);

  const updateEvents = (events: IAssignmentEvent[]) => {
    setEvents((prev) => {
      events.forEach((event) => {
        prev.set(getKey(event), event);
      });
      return new Map(prev);
    });
  };

  const criticalEventTypes = useMemo(
    () =>
      Array.from(eventTypes.entries()).reduce<Set<string>>((acc, [key, value]) => {
        value.isCritical && acc.add(key);
        return acc;
      }, new Set()),
    [eventTypes]
  );

  const checkEventAndNotify = (event: IAssignmentEvent) => {
    if (criticalEventTypes.has(event.eventType) && !events.has(getKey(event)) && event.status === 'NotHandled') {
      setCriticalEvents((prev) => new Map(prev.set(getKey(event), event)));
      silenceConfig.withSound && sound.current?.play();
      isShrunk && setIsShrunk(false);
    }
  };

  const eventSocketCallback = useCallback(
    (event: IAssignmentEvent) => {
      if (!mountedRef.current) return;

      if (silenceConfig.time) {
        if (isSilenceTimeExpired(silenceConfig)) {
          setSilenceConfig({
            start: 0,
            time: 0,
            withSound: silenceConfig.withSound
          });
        }
      } else {
        checkEventAndNotify(event);
      }

      const existingEvent = events.get(getKey(event));
      const existingCriticalEvent = criticalEvents.get(getKey(event));

      if (!existingEvent || existingEvent.status !== event.status) {
        updateEvents([event]);
      }

      if (existingCriticalEvent && existingCriticalEvent.status !== event.status) {
        setCriticalEvents((prev) => new Map(prev.set(getKey(event), event)));
      }
    },
    [mountedRef, events, updateEvents, criticalEvents, criticalEventTypes, silenceConfig, isShrunk]
  );

  useEffect(() => {
    const localSilenceConfig = localStorage.getItem(`${name}:silenceConfig`);

    if (localSilenceConfig) {
      const parsedSilenceConfig = JSON.parse(localSilenceConfig);

      if (!isSilenceTimeExpired(parsedSilenceConfig)) {
        setSilenceConfig({
          ...parsedSilenceConfig,
          time: parsedSilenceConfig.time === null ? Infinity : parsedSilenceConfig.time
        });
      } else {
        setSilenceConfig({...parsedSilenceConfig});
      }
    }
  }, []);

  useEffect(() => {
    if (isShrunk) {
      setCriticalEvents(new Map());
    }
  }, [isShrunk]);

  useEffect(() => {
    localStorage.setItem(`${name}:silenceConfig`, JSON.stringify(silenceConfig));
  }, [silenceConfig]);

  useEffect(() => {
    fetchAssignmentEvents().then((events) => {
      if (mountedRef.current) {
        updateEvents(events);
      }
    });
  }, []);

  useNotificationSocket<IAssignmentEvent>('AssignmentEvent', eventSocketCallback);

  return (
    <EventsCtx.Provider
      value={useMemo(() => {
        return {
          map: events,
          list: [...events.values()].sort((a, b) => b.timestamp - a.timestamp),
          criticalEvents,
          setCriticalEvents,
          criticalEventTypes,
          silenceConfig,
          setSilenceConfig,
          removeEvent
        };
      }, [removeEvent, events, silenceConfig, criticalEvents])}>
      {children}
    </EventsCtx.Provider>
  );
};

export function useEvents() {
  return useContext(EventsCtx);
}
