/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC} from 'react';
import {Button, ButtonProps} from 'primereact/button';
import {cx} from '../../utils/cx';

export const SvgButton: FC<ButtonProps> = ({className, ...rest}) => {
  return <Button className={cx(className, 'p-button-icon-only')} {...rest} css={buttonCss} />;
};

// language=SCSS
const buttonCss = css`
  & {
    > svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
