import {ObjectType} from '@netvision/lib-api-gateway';
import {AssignmentEventStatus} from '../ts';

export const localesRU = {
  eventStatusLocale: {
    [AssignmentEventStatus.ViolationDetected]: 'Тревога',
    [AssignmentEventStatus.FalsePositive]: 'Ложное',
    [AssignmentEventStatus.Handled]: 'Угрозы нет',
    [AssignmentEventStatus.NotHandled]: 'Не обработано'
  },
  commandsLocale: {
    dismiss: 'Скрыть'
  },
  notificationSettingsTableLocale: {
    commonSettings: 'Общие настройки',
    userSettings: 'Пользовательские настройки',
    assigmentPriority: 'Приоритет',
    assigmentType: 'Тип аналитики',
    anyPriority: 'Любой приоритет',
    anyAssignmentType: 'Любой тип аналитики',
    sendNotification: 'Отправлять уведомления',
    showAlarm: 'Нуждается в реагировании',
    handlingTimeout: 'Время на реакцию',
    save: 'Сохранить',
    add: 'Добавить',
    cancel: 'Отмена',
    headerEdit: 'Редактировать настройку',
    headerCreate: 'Создать настройку',
    yes: 'Да',
    no: 'Нет',
    confirmDelete: 'Удалить настройку'
  },
  reactionDialog: {
    alert: 'Тревога',
    falsePositive: 'Ложное',
    handled: 'Угрозы нет',
    assignment: 'Аналитика',
    camera: 'Камера',
    date: 'Дата',
    holdPressed: 'Удерживайте кнопку!'
  },
  eventFieldsLocale: {
    timeLabel: 'Время',
    endAtLabel: 'Конец',
    inProcess: 'Еще продолжается',
    eventTypeLabel: 'Тип события',
    statusLabel: 'Статус',
    reportedLabel: 'Обработано',
    objectLabel: 'Объект',
    assignmentNameLabel: 'Аналитика',
    assignmentTypeLabel: 'Тип аналитики',
    priorityLabel: 'Приоритет',
    plateNumberLabel: 'Номер ГРЗ',
    vehicleBrandLabel: 'Марка ТС',
    vehicleModelLabel: 'Модель ТС',
    vehicleSpeedLabel: 'Скорость',
    speedUnknown: 'Не определена',
    modelUnknown: 'Не определена',
    brandUnknown: 'Не определена'
  },
  externalFilePreviewLocale: {
    previewIsNotReady: 'Превью кадр еще в обработке',
    previewIsNotFound: 'Превью кадр не найден',
    previewLoadingError: 'Не удалось загрузить превью'
  },
  count: {
    [ObjectType.Person]: 'Люди',
    [ObjectType.Car]: 'Транспорт',
    [ObjectType.Bicycle]: 'Велосипеды',
    [ObjectType.Motorcycle]: 'Мотоциклы',
    [ObjectType.Animal]: 'Животные',
    [ObjectType.Roadsign]: 'Дорожные знаки'
  },
  silence: {
    '10min': '10 минут',
    '30min': '30 минут',
    '1h': '1 час',
    '3h': '3 часа',
    '8h': '8 часов',
    forever: 'Бессрочно',
    turnOnNotifications: 'Включить оповещения',
    turnOffNotifications: 'Отключить оповещения',
    turnOnNotificationSound: 'Включить звук оповещений',
    turnOffNotificationSound: 'Отключить звук оповещений'
  },
  toasts: {
    notificationsDisabledSummary: 'Напоминание',
    notificationsDisabledDetails: 'У вас отключены уведомления о критических событиях'
  },
  events: 'Активные события',
  settings: 'Настройки',
  counters: 'Счетчики',
  archiveLabel: 'Архив'
};
