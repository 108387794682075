/** @jsx jsx */
import {jsx} from '@emotion/react';
import React, {FC, useEffect, useState} from 'react';
import {IAssignmentEvent} from '@netvision/lib-api-gateway';
import {genElementId} from '../../utils/genElementId';
import {EventDetailsAdapter} from './EventDetailsAdapter';

interface Props {
  event: IAssignmentEvent;
  onHide: () => void;
}

export const ReactionDialog: FC<Props> = ({event, onHide}) => {
  const [id] = useState(genElementId);
  const [style] = useState(() => {
    const styleEl = document.createElement('style');
    styleEl.textContent = `
      div#${id} > .p-dialog-mask {
        display: block;
        padding: calc(30rem / var(--bfs));
        width: calc(100% - calc(375rem / var(--bfs)));
        backdrop-filter: blur(3px);
      }
      div#${id} > .p-dialog-mask > div {
        width: 100%;
        height: calc(100vh - 60rem / var(--bfs));
      }
    `;
    return styleEl;
  });
  useEffect(() => {
    document.head.appendChild(style);
    return () => {
      style.remove();
    };
  }, [style]);

  return <EventDetailsAdapter containerId={id} event={event} onHide={onHide} />;
};
