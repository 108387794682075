/** @jsx jsx */
import {css, jsx} from '@emotion/react';
import {FC, Fragment, useMemo} from 'react';

const fillPrimaryCss = css`
  & {
    fill: var(--primary-color);
  }
`;

const fillTextSecondaryCss = css`
  & {
    fill: var(--text-color-secondary);
  }
`;

const fillTextSilenceCss = css`
  & {
    fill: var(--text-color-secondary);
    transform: translate(15%, 15%);
  }
`;

export const AlarmIcon: FC<{isSilence: boolean; isActive?: boolean}> = ({isSilence, isActive = false}) => {
  const dCoords = useMemo(() => {
    return isActive
      ? 'M24 20.9C23.6769 20.9656 23.3425 21 23 21C20.2386 21 18 18.7614 18 16C18 13.6004 19.6903 11.5956 21.9453 11.1115C21.4137 10.6823 20.769 10.3628 20 10.18V8H16V10.18C13.13 10.86 12 13.42 12 16.5L12 22L10 23V25H26V23L24 22V20.9ZM18 28C18.14 28 18.27 27.99 18.4 27.96C19.05 27.82 19.58 27.38 19.84 26.78C19.94 26.54 19.99 26.28 19.99 26H15.99C16 27.1 16.89 28 18 28Z'
      : 'M24 16.5C24 13.43 22.86 10.86 20 10.18V8H16V10.18C13.13 10.86 12 13.42 12 16.5L12 22L10 23V25H26V23L24 22L24 16.5ZM18.4 27.96C18.27 27.99 18.14 28 18 28C16.89 28 16 27.1 15.99 26H19.99C19.99 26.28 19.94 26.54 19.84 26.78C19.58 27.38 19.05 27.82 18.4 27.96Z';
  }, [isActive]);

  const svgPath = useMemo(() => {
    if (!isSilence) {
      return <path fillRule="evenodd" clipRule="evenodd" fill="white" d={dCoords} css={fillTextSecondaryCss} />;
    }

    return (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="white"
        d="M20.84,22.73L18.11,20H3V19L5,17V11C5,9.86 5.29,8.73 5.83,7.72L1.11,3L2.39,1.73L22.11,21.46L20.84,22.73M19,15.8V11C19,7.9 16.97,5.17 14,4.29C14,4.19 14,4.1 14,4A2,2 0 0,0 12,2A2,2 0 0,0 10,4C10,4.1 10,4.19 10,4.29C9.39,4.47 8.8,4.74 8.26,5.09L19,15.8M12,23A2,2 0 0,0 14,21H10A2,2 0 0,0 12,23Z"
        css={fillTextSilenceCss}
      />
    );
  }, [dCoords, isSilence]);

  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      {isActive ? (
        <Fragment>
          <circle cx="23" cy="16" r="4" fill="#3C72FF" css={fillPrimaryCss} />
          {svgPath}
        </Fragment>
      ) : (
        svgPath
      )}
    </svg>
  );
};
